import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { Deck } from '../../interfaces/decks';

interface MoveCardPopupProps {
  cards: number[];
  deck: number;
  language: string;
  onSubmit: () => void;
  onClose: () => void; // Function to close the popup
}

const MoveCardPopup: React.FC<MoveCardPopupProps> = ({
  cards,
  deck,
  language,
  onClose,
  onSubmit,
}) => {
  const [decks, setDecks] = useState<Deck[]>([]);
  const [selectedDeck, setSelectedDeck] = useState<number>(deck);

  const fetchDecks = useCallback(async () => {
    try {
      const response = await axios.get(`/api/decks/language_code/${language}`);
      let data: Deck[] = await response.data;
      data = data.filter((d) => d.id !== deck);
      setDecks(data);
      setSelectedDeck(data[0].id);
    } catch (error) {
      console.error('Error fetching decks:', error);
    }
  }, [language, deck]);

  useEffect(() => {
    fetchDecks();
  }, [fetchDecks]);

  const handleMove = async () => {
    try {
      await axios.put(`/api/cards/move/deck/${selectedDeck}`, cards);
      // Close the popup after successful update
      onSubmit();
    } catch (error) {
      console.error('Error moving card:', error);
      onClose();
    }
  };

  const handleDeckChange = (id: string) => {
    const selectedId = parseInt(id, 10);
    setSelectedDeck(selectedId);
  };

  return (
    <div className="phn-popup-overlay">
      <div className="phn-white-card phn-popup" style={{ width: '500px' }}>
        <h2>Move Card</h2>
        <div className="phn-popup-form">
          <label htmlFor="decks">
            Decks
            <select
              value={selectedDeck || ''}
              onChange={(e) => {
                handleDeckChange(e.target.value);
              }}
            >
              {decks.map((deck) => (
                <option key={deck.id} value={deck.id}>
                  {deck.name}
                </option>
              ))}
            </select>
          </label>
          <div className="phn-popup-button-container">
            <button className="phn-button" onClick={handleMove}>
              Move
            </button>
            <button className="phn-cancel-button" onClick={onClose}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoveCardPopup;
