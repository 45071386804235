import axios from 'axios';
import { format, parseISO } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { FaEdit, FaExchangeAlt, FaTrash } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import DataTable from '../../components/table/DataTable';
import { useUser } from '../../context/UserContext';
import { CardData } from '../../interfaces/cards';
import { Deck } from '../../interfaces/decks';
import ConfirmDeletePopup from '../../popups/confirmDeletePopup.tsx/ConfirmDeletePopup';
import EditCardPopup from '../../popups/editCard/EditCardPopup';
import MoveCardPopup from '../../popups/moveCard/MoveCardPopup';
import './CardsPage.css';

const CardsPage: React.FC = () => {
  const { userData } = useUser();
  const languageCode = userData?.learning_language_code;
  const [decks, setDecks] = useState<Deck[]>([]);
  const [selectedDeck, setSelectedDeck] = useState<number | null>(null);
  const [selectedCards, setSelectedCards] = useState<number[]>([]);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [cards, setCards] = useState<CardData[]>([]);
  const [cardToEdit, setCardToEdit] = useState<CardData>();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [showEditPopup, setShowEditPopup] = useState<boolean>(false);
  const [showMovePopup, setShowMovePopup] = useState<boolean>(false);
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();

  const fetchDecks = useCallback(async () => {
    try {
      const response = await axios.get(
        `/api/decks/language_code/${languageCode}`
      );

      const data: Deck[] = response.data;
      setDecks(data);
      const state = location.state as { selectedDeck?: number };
      if (location.state && state.selectedDeck) {
        setSelectedDeck(state.selectedDeck);
      } else {
        setSelectedDeck(data[0]?.id || null);
      }
    } catch (error) {
      console.error('Error fetching decks:', error);
    }
  }, [location.state, languageCode]);

  const fetchCards = useCallback(async () => {
    setSelectedCards([]);
    setSelectedRows([]);
    setCardToEdit(undefined);
    if (selectedDeck === null) return;

    try {
      setLoading(true);
      const response = await axios.get(`/api/cards/deck/${selectedDeck}`);

      const data = response.data;
      setCards(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching cards:', error);
    }
  }, [selectedDeck]);

  useEffect(() => {
    fetchDecks();
  }, [fetchDecks]);

  useEffect(() => {
    fetchCards();
  }, [fetchCards, selectedDeck]);

  const handleAddCard = () => {
    if (selectedDeck !== null) {
      navigate(`/add-card`, { state: { deckId: selectedDeck } });
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleDeckChange = (id: string) => {
    const selectedId = parseInt(id, 10);
    setSelectedDeck(selectedId);
  };

  const toggleCardSelection = (cardId: number) => {
    setSelectedCards((prevSelectedCards) => {
      if (prevSelectedCards.includes(cardId)) {
        return prevSelectedCards.filter((id) => id !== cardId);
      } else {
        return [...prevSelectedCards, cardId];
      }
    });
  };

  const filteredCards = cards.filter(
    (card) =>
      card.target_language.toLowerCase().includes(searchQuery.toLowerCase()) ||
      card.native_language.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const submitCardEdit = () => {
    setShowEditPopup(false);
    fetchCards();
  };

  const submitCardMove = () => {
    setShowMovePopup(false);
    fetchCards();
  };

  const confirmDeleteCard = async () => {
    if (selectedCards.length >= 1) {
      try {
        await axios.delete(`/api/cards`, {
          data: selectedCards,
        });

        fetchCards();
      } catch (error) {
        console.error('Error deleting the cards:', error);
      }
    }
    setShowDeletePopup(false);
    fetchCards();
  };

  const canEditCard = cardToEdit && languageCode && selectedCards.length === 1;
  const canMoveCard =
    selectedDeck &&
    decks.length > 1 &&
    languageCode &&
    selectedCards.length >= 1;
  const canDeleteCard = selectedCards.length >= 1;

  return (
    <>
      <div className="phn-page-container">
        <h1>Cards</h1>
        <div className="phn-vertical-container">
          <div className="phn-horizontal-group">
            <label>Deck:</label>
            <select
              className="phn-dropdown cards-deck-select"
              value={selectedDeck || ''}
              onChange={(e) => {
                handleDeckChange(e.target.value);
              }}
            >
              {decks.map((deck) => (
                <option key={deck.id} value={deck.id}>
                  {deck.name}
                </option>
              ))}
            </select>
            <button
              className="phn-button"
              onClick={handleAddCard}
              disabled={!selectedDeck}
            >
              Add Card
            </button>
          </div>
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
            className="phn-input "
          />
          <div className="phn-horizontal-group">
            <button
              className="phn-button-small"
              disabled={!canEditCard}
              onClick={(e) => {
                e.stopPropagation();
                setShowEditPopup(true);
              }}
            >
              Edit <FaEdit />
            </button>
            <button
              className="phn-button-small"
              disabled={!canMoveCard}
              onClick={(e) => {
                e.stopPropagation();
                setShowMovePopup(true);
              }}
            >
              Move <FaExchangeAlt />
            </button>
            <button
              className="phn-button-small"
              disabled={!canDeleteCard}
              onClick={(e) => {
                e.stopPropagation();
                setShowDeletePopup(true);
              }}
            >
              Delete <FaTrash />
            </button>
          </div>
          <div className="cards-data-table-container">
            <DataTable
              headers={['Phrase', 'Meaning', 'Card Type', 'Next Review']}
              rows={filteredCards.map((card) => [
                card.target_language,
                card.native_language,
                card.card_type,
                card.next_review_at
                  ? format(parseISO(card.next_review_at), 'MM/dd/yy')
                  : '',
              ])}
              widths={['auto', 'auto', '17%', '14%']}
              onRowClick={(index) => {
                toggleCardSelection(filteredCards[index].id);
                setCardToEdit(filteredCards[index]);
              }}
              selectedItems={selectedRows}
              setSelectedItems={setSelectedRows}
              isSelectable={true}
              loading={loading}
              itemsPerPage={20}
            />
          </div>
        </div>
      </div>
      {canEditCard && showEditPopup && (
        <EditCardPopup
          card={cardToEdit}
          language_code={languageCode}
          onClose={() => setShowEditPopup(false)}
          onSubmit={submitCardEdit}
        />
      )}
      {canMoveCard && showMovePopup && (
        <MoveCardPopup
          cards={selectedCards}
          deck={selectedDeck}
          language={languageCode}
          onClose={() => setShowMovePopup(false)}
          onSubmit={submitCardMove}
        />
      )}
      {canDeleteCard && showDeletePopup && (
        <ConfirmDeletePopup
          onClose={() => setShowDeletePopup(false)}
          onConfirm={confirmDeleteCard}
          message="Are you sure you want to delete the selected cards?"
        />
      )}
    </>
  );
};

export default CardsPage;
